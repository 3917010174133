<template>
  <b-row>
    <b-col cols="12">
      <b-card-code>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Procurar:</label>
              <b-form-input
                v-model="searchTerm"
                placeholder=" Procurar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="products"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <!-- Slot: Table Row -->
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span class="text-nowrap" v-if="props.column.field === 'name'">
              <span>
                {{ props.row.name }}
              </span>
            </span>
            <!-- Column: Name -->
            <span class="text-nowrap" v-if="props.column.field === 'actions'">
              <span>
                <b-form-checkbox
                  :checked="props.row.active"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  @change="edit(props.row)"
                />
              </span>
            </span>
            <!-- Column: link -->
            <span class="text-nowrap" v-if="props.column.field === 'link'">
              <a
                :href="`https://www.visaoalpha.com.br/cadastrar/${props.row._id}`"
                target="_blank"
              >
                {{ props.row._id }}
              </a>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Máximo por página </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  Total de <b>{{ props.total }}</b> Cadastros
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VueGoodTable } from "vue-good-table";

import store from "@/store/index";

import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  components: {
    BCardCode,
    VueGoodTable,
  },
  data() {
    return {
      searchTerm: "",
      columns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Link",
          field: "link",
        },
        {
          label: "Cadastro",
          field: "actions",
        },
      ],
      products: [],
      pageLength: 10,
    };
  },
  methods: {
    getProduct() {
      axios.get(`${baseApiUrl}/produtos`).then((res) => {
        if (res.data.success) {
          this.products = res.data.data;
        }
      });
    },
    edit(produto) {
      produto.active = !produto.active;
      axios.put(`${baseApiUrl}/produtos`, {
        produto,
      });
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getProduct();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.clique:hover {
  cursor: pointer;
  transform: scale(1.5);
  transition: all 0.5s;
}
</style>